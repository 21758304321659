<template>
  <basic-layout :header="campus">
    <div class="bg-white bg-opacity-90">
      <container class="">
        <section class="lg:grid lg:grid-cols-12 py-20">
          <div class="lg:col-span-10 p-8 md:p-20">
            <history-details :campus="campus" :librarians="librarians" :titles="titles" :history="history"></history-details>
          </div>
          <div class="lg:col-span-2 hidden lg:block py-28">
            <history-related-links/>
          </div>
        </section>
      </container>
    </div>
  </basic-layout>
</template>

<script>
import HistoryDetails from "../../widgets/HistoryDetails";
import BasicLayout from "../../layouts/BasicLayout";
import Container from "../../layouts/Container";
import HistoryRelatedLinks from "../../widgets/HistoryRelatedLinks";
export default {
  name: "CalapanCityHistory",
  components: {HistoryRelatedLinks, Container, BasicLayout, HistoryDetails},
  data:()=>({
    campus: "Calapan City Campus",
    titles: ['NAME','YEAR','SUPERINTENDENT/PRESIDENT'],
    history: ['The MinSCAT Calapan City Campus Library started when the Calapan School of Arts and Trades was established by virtue of R.A. 3397 in 1966 under the supervision of Mr. Robinson Ylagan.',
      'The accessioning process of library materials started in July 14, 1987. A total of 1,056 numbers of books recorded in the year 1988, the exit year of Mr. Bernardo N. Buenavista as Vocational School Administrator, and Mr. Emelito S. Matunan, the successor in the position.',
      'Upon exit of the retired librarian last May 31, 2015, a licensed librarian and a licensed teacher, Master in Library and Information Science, spearheaded the renovation of library facilities, rearranged the different sections, and reorganized the library materials. Qualified and competent Library staffs was hired to assist in the different library operations from the technical and mechanical processes of library materials to the development, maintenance and monitoring of an in-house Library System, and in-charge of circulation/charging desk.',
      'The core collection of 10,600 totals number of volumes accessioned supports the instruction, research and other programs of the institution. The collection is organized according to the Dewey Decimal Classification System and Anglo American Cataloguing Rules (AACR2) Code of Cataloguing.',
      'Integrated Library System developed by Chresaliz M. Abdon and Leorick Miciano, BSIT graduates of the College, facilitates the organization of library resources. Integrated library system offers; circulation on-line; computerized cataloguing; inventory reporting; serials control; internet searching; CDROM services; and for future implementation of bar coding and OPAC (on-line public access).',
      'The MCC library provides multimedia services; internet access in E-library section is available for 82 titles of e-books purchased by the institution; and the Science and Technology Academic and Research-Based Openly Operated Kiosk Station known as STARBOOKS contains thousands of digitized science and technology resources is also free access to faculty and students.',
      'The MCC library is strategically located and is accessible to students, faculty and other clientele with the total area of 423.75 square meters, meets the standard requirements considering the number of enrolment that can accommodates at any given time. '],
    librarians:[
      {name: 'Eden Genil',year:'1966',position:'Vocational School Administrator',headNames:'Mr. Robinson Ylagan'},
      {name: 'Jovita B. Pajado',year:'July 1974 – August 31, 1977',position:'Vocational School Administrator',headNames:'Mr. Severo E. Abas'},
      {name: 'Shirley R. Tangonan',year:'January 9, 1978 – May 31, 2015',position:'Vocational School Administrators , Presidents',headNames:'Mr. Eufenio P. Valdriz; Mr. Bernardo N. Buenavista; Mr. Emelito S. Matunan; Dr. Angelito S. Bacudo; Dr. Jesse T. Zamora'},
      {name: 'Natividad R. Templanza',year:'June 1, 2015 – August 2018',position:'President',headNames:'Dr. Jesse T. Zamora'},
      {name: 'Leonor E. Rubia',year:'2018-present',position:'President',headNames:'Dr. Jesse T. Zamora; Dr. Levy B. Arago, Jr.'},
    ]
  })
}
</script>

<style scoped>

</style>>